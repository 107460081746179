import React from "react"
import { css } from "@emotion/core"
import Layout from "~/components/common/Layout"
import {
  getBackLink,
  getHeaderTitles,
  getNextLink,
  getNextTitle,
} from "~/services/pages"
import withProgress from "~/services/withProgress"
import { graphql, useStaticQuery } from "gatsby"
import HeroVideo from "~/components/page/HeroVideo"
import { Infobox, InfoboxDetailed } from "~/components/page/Infobox"
import { Box, Flex } from "reflexbox"
import { Heading, Text, Ul } from "~/components/common/Typography"
import GraphicWithText from "~/components/page/GraphicWithText"
import { mq } from "~/components/common/theme"
import Copyright from "~/components/common/Copyright"

import handGif from "~/images/4.5/4.5.1_ADIDAS_WW.V2.gif"
import Source from "~/components/common/Source"

const HandWrittenHeader = ({ children, ...props }) => (
  <Text
    uppercase
    fontSize={["24px", "32px"]}
    lineHeight={["36px", "36px"]}
    fontFamily={"PermanentMarkerRegular"}
    color={"grey-text"}
    mt={3}
    mb={2}
    {...props}
  >
    {children}
  </Text>
)

const Page = ({ myPage = 3, mySubpage = 4 }) => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(name: { eq: "4.5HERO" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      rat1: file(name: { eq: "4.5a-ADIDAS-rat1" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      rat2: file(name: { eq: "4.5a-ADIDAS-rat2" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      rat3: file(name: { eq: "4.5a-ADIDAS-rat3" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      rat4: file(name: { eq: "4.5a-ADIDAS-rat4" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const { hero, rat1, rat2, rat3, rat4 } = data

  const graphicsData = [
    {
      image: rat1,
      title: "The setup:",
      text: `A group of researchers looked at two groups of rats. One group lived in a cage with rat wheels, and one group lived in a cage where the rat wheels were locked. Rats like exercise; they have been shown to run as much as 2.5 miles per night in laboratory environments.`,
    },
    {
      image: rat2,
      title: "The trigger:",
      text: `After the rats lived in these environments for a while, they were exposed to cold water, a stressful environment for them. In the water, there was a path they could find that would take them up and out of the water.`,
    },
    {
      image: rat3,
      title: "The result:",
      text: `The rats with no physical activity panicked when they experienced the water. Their heart rates went up, and they were not able to get out of the water. Examinations of their brains showed all the fight-or-flight neurons going crazy. The rats with physical activity had a different response to the water. They were able to figure out a way out. Examinations of their brains found that the chemicals that calm neurons, inhibit arousal, and prevent the amygdala from going crazy were all in action. The brain protected itself.`,
    },
    {
      image: rat4,
      title: "The conclusion:",
      text: `Physical activity makes a difference in the brain’s ability to activate defense mechanisms during stressful situations.`,
    },
  ]

  const graphicMq = mq({
    width: ["100%"],
  })

  return (
    <Layout
      myPage={myPage}
      mySubpage={mySubpage}
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getBackLink(myPage, mySubpage)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
        nextLink: `${getNextLink(myPage, mySubpage)}`,
      }}
    >
      <HeroVideo
        headingLarge="The brain is not a muscle, but it can work like one."
        image={hero}
        bg={"white"}
      />

      <Infobox uppercase>
        The brain has “plastic” or malleable qualities. It is use-dependent: how
        it is used will affect how it’s going to work best. This is why when you
        start learning a new skill, such as a new language, you get better at it
        over time. So how does this work?
      </Infobox>

      <InfoboxDetailed
        headerText={"So how does this work?"}
        maxWidth={"100%"}
        contentMaxWidth={"desktop"}
      >
        <Flex flexDirection={"column"}>
          <Flex flexDirection={["column", "row"]} mt={6}>
            <Flex width={["100%", "50%"]} justifyContent={"center"}>
              <Box
                width={"100%"}
                mb={[5, 0]}
                mt={4}
                maxHeight={"400px"}
                maxWidth={"400px"}
              >
                <img
                  src={handGif}
                  alt={"hand gif"}
                  css={css`
                    width: 100%;
                  `}
                ></img>
              </Box>
            </Flex>
            <Box width={["100%", "50%"]} px={6} textAlign={"left"}>
              <Text
                uppercase
                fontSize={["18px", "24px"]}
                lineHeight={["27px", "36px"]}
                fontFamily={"PermanentMarkerRegular"}
                mt={0}
                mb={2}
              >
                Step one
              </Text>
              <Text mt={0} mb={["32px", "32px", "64px"]}>
                Grab a pen and a scrap piece of paper, and sign your name as if
                you are signing a contract or a check.
              </Text>
              <Text
                uppercase
                fontSize={["18px", "24px"]}
                lineHeight={["27px", "36px"]}
                fontFamily={"PermanentMarkerRegular"}
                mt={0}
                mb={2}
              >
                Step two
              </Text>
              <Text mt={0}>
                Now switch hands and sign your name with your non-dominant hand.
              </Text>
            </Box>
          </Flex>

          <Box mt={5}>
            <Text mb={0}>
              Unless you are ambidextrous, this might have been pretty hard.
              Your brain is wired to sign with your dominant hand.
            </Text>
            <Text mt={0}>
              But if you went to work and practiced this every single day, you
              would likely get better.
            </Text>
            <Text
              uppercase
              fontSize={["18px", "24px"]}
              lineHeight={["27px", "36px"]}
              fontFamily={"AdineuePROBlackWeb"}
              color={"black"}
              mb={["24px", "24px", "40px"]}
            >
              So what would it take to get better at this, to rewire the brain?
            </Text>
          </Box>

          <Box css={mq({ display: ["none", "none", "block"] })}>
            <Flex flexDirection={"row"} justifyContent={"center"}>
              <Box px={6}>
                <HandWrittenHeader>Practice</HandWrittenHeader>
              </Box>
              <Box px={6}>
                <HandWrittenHeader>Time</HandWrittenHeader>
              </Box>
              <Box px={6}>
                <HandWrittenHeader>Repetition</HandWrittenHeader>
              </Box>
            </Flex>

            <Flex flexDirection={"row"} justifyContent={"center"} mt={"24px"}>
              <Box px={6}>
                <HandWrittenHeader>Support</HandWrittenHeader>
              </Box>
              <Box px={6}>
                <HandWrittenHeader>Flexibility</HandWrittenHeader>
              </Box>
              <Box px={6}>
                <HandWrittenHeader>Patience</HandWrittenHeader>
              </Box>
              <Box px={6}>
                <HandWrittenHeader>Skill-building</HandWrittenHeader>
              </Box>
            </Flex>
          </Box>

          <Box css={mq({ display: ["block", "block", "none"] })}>
            <Flex flexDirection={"row"} justifyContent={"space-around"}>
              <Box>
                <HandWrittenHeader>Practice</HandWrittenHeader>
              </Box>
              <Box>
                <HandWrittenHeader>Time</HandWrittenHeader>
              </Box>
            </Flex>
            <Flex
              flexDirection={"row"}
              justifyContent={"space-around"}
              mt={"24px"}
            >
              <Box>
                <HandWrittenHeader>Repetition</HandWrittenHeader>
              </Box>
              <Box>
                <HandWrittenHeader>Support</HandWrittenHeader>
              </Box>
            </Flex>

            <Flex
              flexDirection={"row"}
              justifyContent={"space-around"}
              mt={"24px"}
            >
              <Box>
                <HandWrittenHeader>Flexibility</HandWrittenHeader>
              </Box>
              <Box>
                <HandWrittenHeader>Patience</HandWrittenHeader>
              </Box>
            </Flex>
          </Box>

          <Box
            px={5}
            mt={"24px"}
            css={mq({ display: ["block", "block", "none"] })}
          >
            <HandWrittenHeader>Skill-building</HandWrittenHeader>
          </Box>

          <Box px={5} mt={"24px"}>
            <HandWrittenHeader
              fontSize={["32px", "48px"]}
              lineHeight={["36px", "56px"]}
            >
              PHYSICAL ACTIVITY!
            </HandWrittenHeader>
          </Box>
          <Box
            px={5}
            mt={6}
            maxWidth={"mediumContent"}
            mx={"auto"}
            width={"100%"}
          >
            <Text
              fontSize={["18px", "24px"]}
              lineHeight={["27px", "36px"]}
              color={"grey-text"}
              mt={0}
              mb={2}
            >
              One of the most effective ways to train a brain is by leveraging
              physical activity. Physical activity benefits the brain as much as
              the body.<Source>33</Source>
            </Text>
          </Box>
        </Flex>
      </InfoboxDetailed>

      <InfoboxDetailed
        headerText={"How it all works"}
        headerBackground={"transparent"}
        headerMaxWidth={"desktop"}
        maxWidth={"100%"}
        contentMaxWidth={"desktop"}
        bg={"seafoam"}
        pt={6}
      >
        <Box bg={"white-panel"} mt="5" px={[5, 5, 6]} py={[6, 6]}>
          <Flex flexDirection={"column"}>
            <Flex
              width={"100%"}
              maxWidth={"994px"}
              mx={"auto"}
              justifyContent={"center"}
              alignItems={"start"}
              css={css`
                flex-wrap: wrap;
              `}
            >
              {graphicsData.map((item, index) => (
                <GraphicWithText
                  mq={graphicMq}
                  image={item.image}
                  width={["100%", "50%"]}
                  key={index}
                  px={6}
                >
                  <Heading
                    uppercase
                    as="h3"
                    fontSize={["18px", "24px"]}
                    lineHeight={["27px", "36px"]}
                    fontFamily={"AdineuePROBlackWeb"}
                  >
                    {item.title}
                  </Heading>
                  <Text mt={0}>{item.text}</Text>
                </GraphicWithText>
              ))}
            </Flex>
          </Flex>
        </Box>
      </InfoboxDetailed>

      <InfoboxDetailed
        headerText={"So how does this all relate back to stress?"}
        headerMaxWidth={"desktop"}
        maxWidth={"100%"}
        contentMaxWidth={"desktop"}
        pt={6}
        pb={[5, 6]}
      >
        <Flex flexDirection={"column"}>
          <Box maxWidth={"mediumContent"} mx={"auto"} width={"100%"}>
            <Text>
              Remember what happens to the body when it experiences stress?
              Sweat, increased heart rate, and tight muscles.
            </Text>
            <Text>
              Now think about what happens to the body during exercise. Sweat,
              increased heart rate, and tight muscles. But during a workout, an
              athlete can do something that they can’t do during a moment of
              stress: they can stop.
            </Text>
            <Text>
              Extreme forms of stress are defined by the loss of control. We
              can’t stop it, so stress responses take over. But when we are
              physically active, we have control over our physiological
              responses. Being physically active helps people take back control
              and practice modulating stress responses.
            </Text>
            <Text>
              Like the rats, when we make exercise and physical activity a
              habit, the brain knows how to send messages down a different route
              to the amygdala, rewiring the stress response. At the most basic
              level, the brain knows how to manage stress better because
              physical activity is a manageable stress that can be controlled.
              It’s good practice.
            </Text>
          </Box>
        </Flex>
      </InfoboxDetailed>

      <InfoboxDetailed
        headerText={"It takes time to rewire the brain"}
        headerMaxWidth={"desktop"}
        maxWidth={"100%"}
        contentMaxWidth={"desktop"}
        pt={0}
      >
        <Flex flexDirection={"column"}>
          <Box maxWidth={"mediumContent"} mx={"auto"} width={"100%"}>
            <Text>
              For girls whose brains have been changed by stress, you as a coach
              can help them rewire it back. With patience, you can make a big
              difference that will equip your girls with the ability to maintain
              control in stressful situations on and off the field.
            </Text>
            <Text uppercase fontFamily={"AdineuePROBoldWeb"}>
              How can you help a girl who has a lot of stress to change her
              behavior?
            </Text>

            <Box mx={"auto"} maxWidth={"600px"}>
              <Ul color={"black"} textAlign={"left"}>
                <li>
                  Recognize that she isn’t here to ruin your day; she’s doing
                  her best.
                </li>
                <li>Practice: Do it over and over.</li>
                <li>Be patient: Know that change takes time.</li>
                <li>
                  Leverage what you can: your relationships, your safe space.
                </li>
              </Ul>
            </Box>
          </Box>
        </Flex>
      </InfoboxDetailed>

      <InfoboxDetailed
        headerText={
          "The cornerstone of everything you can do for your players is to have the knowledge that, if you believe you can grow, you will."
        }
        headerMaxWidth={"desktop"}
        maxWidth={"100%"}
        contentMaxWidth={"desktop"}
        bg={"seafoam"}
        headerBackground={"transparent"}
        pt={6}
      >
        <Box bg={"white-panel"} mt="5" px={[5, 5, 6]} py={[6, 6]}>
          <Flex flexDirection={"column"}>
            <Box maxWidth={"mediumContent"} mx={"auto"} width={"100%"}>
              <Text
                uppercase
                fontSize={["18px", "24px"]}
                lineHeight={["27px", "36px"]}
                fontFamily={"AdineuePROBoldWeb"}
                mt={0}
              >
                The growth mindset
              </Text>

              <Text lineHeight={["24px", "27px"]}>
                Researcher Carol Dweck defines the growth mindset as knowing
                that we can always do new things with confidence, effort, and
                persistence. The opposite of this would be the fixed mindset,
                where we can’t improve or grow what we have in terms of talent,
                brain power, and innate ability over time.
              </Text>
              <Text lineHeight={["24px", "27px"]}>
                At the heart of your every action as a coach is the deep,
                unwavering belief that — with the right combination of love,
                intention, structure, persistence, patience, and understanding —
                you have the power to help a girl improve in some way.
              </Text>
            </Box>
          </Flex>
        </Box>
      </InfoboxDetailed>

      <Copyright bg={"seafoam"} />
    </Layout>
  )
}

export default withProgress(Page, 3, 4)
